/* some default styles go in textstyles.css so the editor looks more like the page.*/



/* ******************************************************************** */ 
/* jquery validate 														 */
/* ******************************************************************** */
.error {
	color:red;
}
/* ******************************************************************** */ 
/* Clearfix: http://csscreator.com/attributes/containedfloat.php        */
/* ******************************************************************** */
.clearfix:after {
 content: "."; 
 display: block; 
height: 0; 
 clear: both; 
 visibility: hidden;
 }
 
.clearfix{display: inline-block;}

/* Hides from IE-mac \*/
* html .clearfix{height: 1%;}
.clearfix{display: block;}
/* End hide from IE-mac */  

/* ******************************************************************** */ 
/* Common elements 														*/
/* ******************************************************************** */
.contentwrap {
	max-width: 990px;
	margin:0 auto;
}
.topshadow {
	position:absolute;
	top:0;
	left:0;
	right:0;
	height:9px;
	background: url('../assets/img/top-shadow.png') repeat-x;
}
.bottomshadow {
	position:absolute;
	bottom:0;
	left:0;
	right:0;
	height:9px;
	background: url('../assets/img/bottom-shadow.png') repeat-x;
}
.positioning {
	position:relative;
}
.topmargin {
	margin-top:40px;
}
.bottommargin {
	margin-bottom:40px;
}
noscript {
	display:block;
	font-size:24px;
}
.center {
	text-align:center;
}
.expand {
	display:none;
	overflow: hidden;
	transition: height 0.5s ease;
}
.expand.active {
	height: 100%;
}
.expando {
	background: url('../assets/img/circle-down-entertainmentvenues.png') right center no-repeat;
	cursor:pointer;
}
.expando.open {
	background: url('../assets/img/circle-up-entertainmentvenues.png') right center no-repeat;
}
.table-wrap {
	display: flex;
	flex-wrap: wrap;
}
.row-wrap {display: flex;width: 100%;justify-content: space-between;}
#content {
	position: relative;
    font-family: "Times New Roman", Times, serif;
}
#upcomingevents, .topbanner {
    font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;
}
.addthis_toolbox a {
	color: #FFFFFF !important;
}
.t-entertainmentvenues #content a {
	color:#fd8300;
}
.t-townhall .news-ad-wrap,
.t-capitoltheatre .news-ad-wrap,
.t-trecc .news-ad-wrap {
    margin-top: 40px;
}
.t-townhall #content > .contentwrap,
.t-capitoltheatre #content > .contentwrap,
.t-trecc #content > .contentwrap {
    margin-bottom: 40px;
}
.t-trecc #content a {
	color:#72B84C;
}
.t-townhall #content a {
	color:#92278F;
}
.t-capitoltheatre #content a {
	color:#E72079;
}
.position {
	position:relative;
}
.topbanner iframe {
	display:inline;
	vertical-align: middle;
}
.t-events-iframe .header-bar-top nav.header-menu {
    display: none;
}
img {
    max-width:100%;
    height: auto;
}
.table-overflow-container {
	overflow-x:auto;
	width:100%;
}
@media all and (max-width:960px) {
    .contentwrap {
        padding:0 5px;
    }
}
.t-event-feed .site-body {
    padding-bottom:50px;
}

.bodywrap table {
    width: 100%;
}
#form-result {
    padding-bottom: 40px;
}
form td {
    vertical-align: top;
    padding: 5px;
}
.t-entertainmentvenues h2.black,
.t-trecc h2.black,
.t-townhall h2.black,
.t-capitoltheatre h2.black {
    color: #000;
}
form.contact-form input,
form.contact-form select,
form.contact-form textarea {
    display: block;
    width: 100%;
    height: 30px;
    padding-left: 20px;
    box-sizing: border-box;
}
form.contact-form textarea  {
    font-family: "Times New Roman", Times, serif;
    font-size: 14px;
    padding-top: 5px;
    min-height: 80px;
}
form button {
    display: inline-block;
    width: 100%;
    height: 30px;
}

/* ******************************************************************** */ 
/* top bar		 														*/
/* ******************************************************************** */
.header-bar-top nav.header-menu {
	padding:10px;
	/*height:120px;*/
	line-height:120px;
	position:relative;
}
.header-bar-top nav.header-menu ul li span.title {
    display: none;
}
.header-bar-top nav.header-menu .logo img {	
	vertical-align:middle;
	width: clamp(200px, 40vw, 400px);
}
.header-bar-top nav.header-menu > ul {
	position:absolute;
	right:0;
    top:20px;
}
.header-bar-top nav.header-menu > ul > li {
	position:relative;
	display:inline-block;
	line-height:normal;
	filter: grayscale(1);
	transition: filter 0.25s ease;
}
.header-bar-top nav.header-menu > ul > li:hover {
	filter: grayscale(0);
}
.header-bar-top nav.header-menu > ul > li img {
	padding-left:40px;
	vertical-align:baseline;
}

@media all and (max-width:760px) {
    .header-bar-top nav.header-menu .logos {
        display:block;
        position: static;
    }
    .header-bar-top nav.header-menu {
        text-align: center;
    }
    .header-bar-top nav.header-menu > ul {
    	position: relative;
    	padding-left: 0;
    	line-height: 1;
    	margin-bottom: 25px;
    }
    .header-bar-top nav.header-menu > ul > li:first-child img {
    	
padding-left: 0;
    }
}
/* ******************************************************************** */ 
/* menu			 														*/
/* ******************************************************************** */
#menu-btn {
	height:29px;
	z-index:100;
	position: relative;
	margin-bottom: -29px;
	cursor:pointer;
}
.t-entertainmentvenues #menu-btn {
	background: url('../assets/img/menu-entertainmentvenues.png') center no-repeat;
}
.t-trecc #menu-btn {
	background: url('../assets/img/menu-trecc.png') center no-repeat;
}
.t-townhall #menu-btn {
	background: url('../assets/img/menu-town-hall.png') center no-repeat;
}
.t-capitoltheatre #menu-btn {
	background: url('../assets/img/menu-capitol-theatre.png') center no-repeat;
}
.menupad {
	padding-top: 45px;
	margin-bottom:45px;
}
.header-bar-bottom {
	height:0;
	overflow:hidden;
	color: #fff;
	transition: height 0.5s ease;
}
.t-entertainmentvenues .header-bar-bottom {
	background: #fd8300;
}
.t-trecc .header-bar-bottom {
	background: #72b84c;
}
.t-townhall .header-bar-bottom {
	background: #92278f;
}
.t-capitoltheatre .header-bar-bottom {
	background: #e72079;
}
.header-bar-bottom.active {
	height: 133px;
}
.main-menu ul {
	text-align:center;
}
.main-menu ul li {
	text-align:left;
}
.main-menu ul, 
.main-menu ul ul {
	list-style:none;
	padding:0;
}
.main-menu ul > li {
	display:inline-block;
	width:auto;
	white-space:nowrap;
	vertical-align:top;
	font-size:18px;
	font-weight:bold;
	text-transform:uppercase;
	padding-right:45px;
}
.main-menu ul li:last-child {
	padding-right:0;
}
.main-menu ul a {
	text-decoration:none;
	color: #FFF;
}
.main-menu ul li li {
	display:block;
	font-size:14px;
	font-weight:normal;
	font-style:italic;
	font-family: "Times New Roman", Times, serif;
	text-transform:none;
	padding-right:0px;
	line-height: 150%;
}
@media all and (max-width:990px) {
.main-menu ul {
        margin:30px;
    }
.main-menu ul > li {
        width:31%;
        box-sizing: border-box;
        white-space: normal;
        margin-bottom: 20px;
    }
}
@media all and (max-width:600px) {
.main-menu ul > li {
        width:49%;
    }
}
@media all and (max-width:400px) {
.main-menu ul > li {
        width:100%;
    }
}
/* ******************************************************************** */ 
/* top banner	 														*/
/* ******************************************************************** */
.t-newsitem .upcoming-cont,
.topbanner,
.background-dots {
	background: url('../assets/img/banner-bg.png');
	position:relative;
}
.background-dots {
	padding-top: 45px;
	padding-bottom: 45px;
}
.topbanner img {
	vertical-align: top;
}
/* ******************************************************************** */ 
/* event item		 													*/
/* ******************************************************************** */
.item {
	display:inline-block;

    
    /* TM */
	width:100%;
	/* overflow:hidden; */
	background-size: cover;
	background-position: center;
	height:0;
}
.item .itemcontent {    
    /* TM */
    overflow:hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
/* TM  
img.tileimage {
    width:100%;
    height:100%;
} */
/*.full img.tileimage,
.small img.tileimage {
    max-width:100%;
    height:auto;
}*/
.item.top {
	padding-top:0;
}
.item.bottom {
	padding-bottom:0;
}
.item.left {
	padding-left:0;
}
.item.right {
	padding-right:0;
}
.itemwrap {
	position:relative;
}
.item.small {	
	width:49%;
	padding-top:49%;
}
.item.placeholder {
}
.item.portrait, .item.landscape {
	overflow:hidden;
}
.item.portrait {
	width:49%;
	padding-top:99%;
}
.item.landscape {
	width:99%;
	padding-top:49%;
}
.item.full {
	width:99%;
	padding-top:99%;
}
.item .footerwrap {
	position:absolute;
	left:0;right:0;
	bottom:0;
}
.item .footer {
	position:relative;
	padding:10px;
}
.item .footer .bg{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color:#000;
	opacity:0.75;
}
/*.item.landscape .itemcontent {
	height:240px;
}
.item.landscape .itemcontent img {
	margin-top:-120px;
}
.item.portrait .itemcontent img {
	margin-left:-120px;
}*/
.item .content {
	line-height:normal;
	font-size:22px;
	color:#fff;
	position:relative;
	padding-bottom:0;
	margin-bottom:-1.5em;
	zoom:1;
	text-transform:uppercase;
	font-weight:bold;
	transition: margin 250ms;
	-moz-transition: margin 250ms;
	-webkit-transition: margin 250ms;
	-o-transition: margin 250ms;
	background-position: 50% 90%;
}
.item.trecc .content,
.item.capitol-theatre .content,
.item.town-hall .content {
	padding-bottom:45px;
	margin-bottom:-45px;
}
.item.noloc .content,
.item.entertainment-venues .content {
	padding-bottom:0;
	margin-bottom:0;
}
.item.noloc.nocat .content {
	padding-bottom:0;
	margin-bottom:0;
}
.item.trecc .content {
	background:url('../assets/img/trecc-logo-tile.png') bottom center no-repeat;
}
.item.capitol-theatre .content {
	background: url('../assets/img/capitol-logo-tile.png') bottom center no-repeat;
}
.item.town-hall .content {
	background:url('../assets/img/town-hall-logo-tile.png') bottom center no-repeat;
}
.item:hover .content {
	margin-bottom:0;
}

.item .content .date {
	font-size:14px;
	text-transform:none;
	font-weight:normal;
}

.item .date {
	color:#fd8300;
}
.item .location {
	font-style:italic;
}
.item.trecc .location,
.item.capitol-theatre .location,
.item.town-hall .location,
.item.entertainment-venues .location {
	display:none;
}
.item.trecc .date {
	color:#72b84c;
}
.item.capitol-theatre .date {
	color:#e72079;
}
.item.town-hall .date {
	color:#92278f;
}
.item .status {
	position:absolute;
	top:5px;
	right:5px;
	width:40px;
	height:40px;
}
.t-entertainmentvenues .item .status.season-event {
	background:url('../assets/img/season-event-entertainmentvenues.png');
}
.t-entertainmentvenues .item .status.festival-event {
	background:url('../assets/img/festival-event-entertainmentvenues.png');
}
.t-trecc .item .status.season-event {
	background:url('../assets/img/season-event-trecc.png');
}
.t-trecc .item .status.festival-event {
	background:url('../assets/img/festival-event-trecc.png');
}
.t-townhall .item .status.season-event {
	background:url('../assets/img/season-event-town-hall.png');
}
.t-townhall .item .status.festival-event {
	background:url('../assets/img/festival-event-town-hall.png');
}
.t-capitoltheatre .item .status.season-event {
	background:url('../assets/img/season-event-capitol-theatre.png');
}
.t-capitoltheatre .item .status.festival-event {
	background:url('../assets/img/festival-event-capitol-theatre.png');
}

.badging {
    overflow: hidden;
    z-index: 1;
    height: 110px;
    width: 110px;
    position: absolute;
    left: -6px;
    top: -6px;
}
.badging span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    padding: 7px 0;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 155px;
    position: absolute;
    top: 30px;
    left: -32px;
    background: white;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}
.badging span::before {
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: '';
    z-index: -1;
    position: absolute;
    left: 0;
    top: 100%;
    border-left: 3px solid #002d33;
    border-top: 4px solid #002d33;
}
.badging span::after {
    border-left: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: '';
    z-index: -1;
    position: absolute;
    right: 0;
    top: 100%;
    border-right: 3px solid #002d33;
    border-top: 4px solid #002d33;
}
.badging.cancelled span {
	background:red;
}
.badging.postponed span {
	background:#FFD462;
}
.badging.sold-out span {
	background:grey;
}
.badging.selling-fast span {
	background:#51B6E6;
}
@media all and (max-width:900px) {
    .item .content {
        font-size: 12px;
        font-size: 2.5vw;
    }
}
/* ******************************************************************** */ 
/* banners		 														*/
/* ******************************************************************** */
.banner-overflow {
    overflow: hidden;
}
#banners {
	position:relative;
	padding-top:40px;
	padding-bottom:20px;
}
#bannernext {
	background: url('../assets/img/banner-next.png');
	width:70px;
	height:70px;
	position:absolute;
	top:50%;
	margin-top:-35px;
	right:-100px;
	cursor:pointer;
}
#bannerprev {
	background: url('../assets/img/banner-prev.png');
	width:70px;
	height:70px;
	position:absolute;
	top:50%;
	margin-top:-35px;
	left:-100px;
	cursor:pointer;
}
#bannerwindow {
	overflow:hidden;
	position:relative;
 /* TM  I added... */
	width:100%;
	margin: -5px;
	padding: 5px;
}
#bannerslider {
	width:100%;
	/* font-size:0; */
	/* line-height:0; */
	white-space: nowrap;
}
#bannerslider .loading {
	position:absolute;
	left:50%;
	top:50%;
	margin-left:-24px;
	margin-top:-24px;
}

.sliderblock {
/* TM - killed 	float:left; / * zoom:1 didn't work to make IE7-8 display these inline.*/
	display:inline-block;
	
    /* TM was orig width: 24.5%; */
    width:50%;
	
	position:relative;
    padding-top:50%;
    white-space:normal;
}

.sliderblock .item {
	position:absolute;
	top:0;
	left:0;
}

.sliderblock .item.bottom {
	top:50%;
}
.sliderblock .item.right {
	left:50%;
}


/* MH -TODO: hide on touch devices? */
@media all and (max-width:1200px) {
    #banners {
        margin:0 100px;
    }
}

/*.banner-img {
    max-width:100%;
}*/
@media all and (max-width:900px) { /* this should match the query defined in site.js:284*/
    #bannerwindow{
        overflow:visible;
    }
    #bannerslider {
        height:0;
        padding-top:200%;
    }
    .sliderblock {
        width:100%;
        padding-top:100%;
        display:block;
        position:absolute;
        top:0;
        left:0;
        -webkit-transition: left 1s ease, top 1s ease;
        -moz-transition: left 1s ease, top 1s ease;
        -ms-transition: left 1s ease, top 1s ease;
        -o-transition: left 1s ease, top 1s ease;
        transition: left 1s ease, top 1s ease;
    }
    .sliderblock.top {
        left:0;
        top:0;
    }
    .sliderblock.bottom {
        left:0;
        top:50%;
    }
    .sliderblock.to-left {
        left:-150%;
    }
    .sliderblock.from-right {
        left:150%;
    }
}
@media all and (max-width:500px) {
    #banners {
        display:none;
    }
    .banner-overflow > .contentwrap {
        padding-top:30px;
    }
}
/* ******************************************************************** */ 
/* enews																*/
/* ******************************************************************** */
.news-ad-wrap, .ad-ad-wrap {
    text-align: center;
}
.ad-ad-wrap div:first-child {
	margin-bottom: 10px;
	margin-right:5px;
}
.enews {
	background-color:#72b84c;
	padding:5px;
	min-height: 56px;
	width:490px;
	margin-right: 5px;
	display:inline-block;
	vertical-align:top;
	zoom:1; /* make IE 5-8 'have layout' on these */
 /*make ie7 think it's inline */
	margin-bottom:10px;
	max-width: 100%;
	box-sizing: border-box;
}
.enews #hidden {
    max-height: 0;
    height: 108px;
    overflow: hidden;
    transition: all 0.3s linear;
}
.enews #hidden.active {
    max-height: 148px;
    height: 148px;
}
.enews h1,
.enews .para, 
.enews #drop-button {
	display:table-cell;
	vertical-align: middle;
	width: 50%;
	margin:0;
	padding:0;
}
.enews #drop-button {
    background-color: #253d18;
    color: #FFF;
    text-transform: uppercase;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.enews h1 {
	color:#253d18;
	vertical-align:middle;
	font-size:22px;
	line-height:56px;
}

.enews input {
	height:39px;
	width:90%;
	margin:5px;
	font-family:"Times New Roman", Times, serif;
	font-style:italic;
	font-size:14px;
	vertical-align:middle;
	padding-left:2px;
}
.enews label.infield {
	position:absolute;
    margin-top: 19px;
    margin-left: 8px;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    font-size: 14px;
    line-height: 14px;
}
.enews .mc-field-group .position {
    display: flex;
    align-items: center;
    margin-left: 5%;
}
.mc-field-group.size1of2 {
    display: flex;
    align-items: center;
}

.datefield {
    display: flex;
    width: 100%;
    align-items: center;
}
.datefield span.subfield {
    padding-right: 15px;
    width: 100%;
}
.enews .subfield .birthday {
    width: 90%;
}
div.submit.position {
    width: 100%;
}
.enews input[type='submit'] {
	font-size:0;
	background-color:transparent;
	background-image: url('../assets/img/submit-enews.png');
	width:32px;
	height:32px;
	border:none;
	vertical-align:middle;
}
.enews .table-wrap {
	/* width:100%; */
	/* table-layout: fixed; */
}
@media all and (max-width:1000px) {
    .enews {
        margin-right: 0;
    }
}
/* ******************************************************************** */ 
/* advert																*/
/* ******************************************************************** */
.advertwrap {
	width:490px;
	display:inline-block;
	vertical-align:top;
	overflow:hidden;
	zoom:1; /* make IE 5-8 'have layout' on these */
 /*make ie7 think it's inline */
}
.advert-aspect {
    height:0;
    padding-top:13.4%;
    width:100%;
    position:relative;
}
.KMS-advert {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.KMS-advert img {
    height:auto !important;
}
@media all and (max-width:500px) {
    .advertwrap {
        max-width:100%;
    }
    .enews {
	   margin-right:0;
    }
}
/* ******************************************************************** */ 
/* newsfeed																*/
/* ******************************************************************** */
.newsfeed {
	position:relative;
	min-height:66px;
	margin: 0 auto;
	margin-top:10px;
	width:100%;
	max-width: 990px;
	padding-bottom:10px;
}
.newsbox {
	background-color:#90268d;
	min-height: 56px;
	padding:5px;
	margin-right:42px;
}
.newsdown, .newsup {
	position:absolute;
	width:32px;
	height:30px;
	background-color: #471144;
	right:0;
	cursor:pointer;
}
.newsdown {
	top:36px;
	background: url('../assets/img/news-down.png') center no-repeat #471144;
}
.newsup {
	top:0;
	background: url('../assets/img/news-up.png') center no-repeat #471144;
}
.newsfeed h1 {
	display:inline-block;
	color:#471144;
	padding:0;
	margin:0;
}
.newsfeed .newsitem {
	font-family:Arial, Helvetica, sans-serif;
	font-size:12px;
	color:#ffffff;
	/* height: 40px; */
	/* overflow: hidden; */
	position: relative;
}
.newsbox table {
	height:100%;
}
.newsbox td.left {
	white-space:nowrap;
	vertical-align:middle;
	padding-right:20px;
}
.newsitem a {
	text-decoration:none;
	color: #FFF !important;
}
.newsitem h2 {
	padding:0;
	margin:0;
	color:#fff;
}
/* ******************************************************************** */ 
/* news															*/
/* ******************************************************************** */
.news {
	padding-bottom:50px;
}
.news .showmore {
	background:url('../assets/img/show-more-white-entertainmentvenues.png') center bottom no-repeat;
	position:absolute;
	bottom:-26px;
	padding-bottom:35px;
	left:0;
	right:0;
	text-align:center;
	z-index:100;
	font-style:italic;
	color:#fd8300;
	font-size:14px;
	font-family:"Times New Roman", Times, serif;
	cursor:pointer;
}
.topbanner .news .showmore {
	background:url('../assets/img/show-more-entertainmentvenues.png') center bottom no-repeat;
}
.newsitems {		
	padding-top:20px;
}
.news .newsitem {
	cursor:pointer;
	width:240px;
	margin-right:10px;
	float:left;
	margin-bottom: 40px;
}
.news .newsitem.right {
	margin-right:0;
}
.news .newsitem h1 {
	font-weight:bold;
	font-size:18px;
}

.news .newsitem .teaser {
	font-family:"Times New Roman", Times, serif;
	font-size:14px;
	color: #000000;
}

.news .newsitem:hover {
	text-decoration:underline;
}
.t-newsitem main {
    position: relative;
}
.news .newsitem .date,
.t-newsitem .date {
	font-size:12px;
	font-weight:bold;
	padding-bottom:10px;
}
.t-entertainmentvenues.t-newsitem .date {
	color:#FD8300;
}
.t-trecc.t-newsitem .date {
	color:#72B84C;
}
.t-townhall.t-newsitem .date {
	color:#92278F;
}
.t-capitoltheatre.t-newsitem .date {
	color:#E72079;
}
.news.newsitem:hover .date {
	text-decoration:underline;
	color:#e72079;
}
.news .header {
	position:relative;
}
.news .controls  {
	position:absolute;
	top:0;
	right:0;
}
#newssearch {
	width:200px;
	height:40px;
	border:1px solid #e9e9e9;
	margin-right:20px;
	vertical-align: middle;
}
.newspage {
	font-family:"Times New Roman", Times, serif;
}
/* ******************************************************************** */ 
/* upcoming																*/
/* ******************************************************************** */
.upcoming {
	padding-top:25px;
	padding-bottom:50px;
	position:relative;
}
.upcoming h1 {
	display:inline-block;
	color:#414141;
}
.upcoming .controls  {
	float:right;
}
.upcoming .controls .legend {
	padding-right:20px;
    margin-bottom: 10px;
    display: inline-block;
}
.controls .searchwrap {
	position:relative;
    display: inline-block;
}
.searchwrap label.infield {
	position:absolute;
	margin-top:9px;
	font-family: "Times New Roman", Times, serif;
	font-style: italic;
	font-size: 14px;
}
.searchwrap label.infield img {
	vertical-align:middle;
	padding-right:10px;
	padding-left:10px;
}
#upcomingsearch {
    padding-left: 20px;
	width:200px;
	height:40px;
	border:1px solid #e9e9e9;
    box-sizing: border-box;
	margin-right:20px;
	vertical-align: middle;
}
.searchwrap .clear {	
	position:absolute;
	top:8px;
	right:28px;
	background-color:#000;
	cursor:pointer;
	height:7px;
	width:7px;
	padding:1px 1px 2px 2px;
}
.controls .viewby {
	position:relative;
	display:inline-block;
	font-family: "Times New Roman", Times, serif;
	font-style: italic;
	font-size: 14px;
	vertical-align:middle;
	cursor: pointer;
}
.controls .viewby img.viewby {
	padding-left:10px;
}

.controls .viewby .hover {
	display:none;
	position:absolute;
	top:0px;
	right:0;
	color:#fff;
	width:auto;
	white-space:nowrap;
	padding:10px;
	z-index:100;
	font-style: normal;
	font-size: 14px;
	font-size: 14px;
	font-weight: normal;
	font-style: italic;
	font-family: "Times New Roman", Times, serif;
	border-radius:10px;
}
.t-entertainmentvenues .controls .viewby .hover {
	background-color:#fd8300;
}
.t-trecc .controls .viewby .hover {
	background-color:#72B84C;
}
.t-townhall .controls .viewby .hover {
	background-color:#92278F;
}
.t-capitoltheatre .controls .viewby .hover {
	background-color:#E72079;
}
.controls .viewby .hover.active {
	display: block;
}
.controls label.infield {
	color:#414141;
}
.controls .viewby  a {
	color:inherit;
}
.controls .viewby .hover a {
	text-decoration:none;
}
.controls .viewby .hover a.active {
	text-decoration:underline;
}
.controls .viewby .hover h2 {	
	font-style: normal;
	font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;
	padding-top:10px;
	padding-bottom:10px;
	margin:0 0 10px 0;
	color:#fff;
}

.viewby .hover .column {
	display:inline-block;
	white-space:normal;
	vertical-align:top;
	border-left: 1px solid #D9D9D9;
	padding-left:20px;
	margin-right: 80px;
}
.viewby .hover .column.left {
	border-left:none;
	padding-left:0;
	width: 172px;
	box-sizing: content-box;
}
.upcoming .item {
    width: 240px;
    padding-top: 240px;
    height: 0;
    position: relative;
    text-align: left;
    vertical-align: bottom;
    background-position: center;
    background-size: cover;
}
.upcoming .item.right {
	margin-right:0;
}
#upcomingevents {
	padding: 10px;
	margin: -10px;
	padding-top: 50px;
	text-align: center;
	margin-right: -20px;
	overflow: hidden;
	transition: height 0.5s ease;
	position: relative;
}
div#loadingscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF75;
    z-index: 5;
    display: block;
}
div#loadingscreen img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#upcomingevents a {
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: bottom;
}
.viewby label.infield, #venue_other {
	height: 40px;
	/* position: absolute; */
	/* z-index: 10000; */
}
.viewby label.infield {
	margin-top: 9px;
	position: absolute;
}
#venue_other {
	border: 1px solid #e9e9e9;
}
.upcoming .showmore {
	position:absolute;
	bottom:-26px;
	padding-bottom:35px;
	left:0;
	right:0;
	text-align:center;
	z-index:100;
	font-style:italic;
	font-size:14px;
	font-family:"Times New Roman", Times, serif;
	cursor:pointer;	
	background: url('../assets/img/show-more-white-entertainmentvenues.png') center bottom no-repeat;
	color:#fd8300;
}
.topbanner .upcoming .showmore {
    background: url('../assets/img/show-more-entertainmentvenues.png') center bottom no-repeat;
}
.t-trecc .upcoming .showmore {
	background: url('../assets/img/show-more-trecc.png') center bottom no-repeat;
	color:#72B84C;
}
.t-townhall .upcoming .showmore {
	background: url('../assets/img/show-more-town-hall.png') center bottom no-repeat;
	color:#92278F;
}
.t-capitoltheatre .upcoming .showmore {
	background: url('../assets/img/show-more-capitol-theatre.png') center bottom no-repeat;
	color:#E72079;
}
.upcoming .legend .filter{
	height:25px;
	width:120px;
	display:inline-block;
	vertical-align:middle;
	cursor:pointer;
}
.upcoming .filter.season-event {
	background:url('../assets/img/s-legend-on.png');
	filter: invert(1);
}
.upcoming .filter.season-event.active {
	filter: none;
}
.upcoming .filter.festival-event {
	background:url('../assets/img/f-legend-on.png');
	filter: invert(1);
}
.upcoming .filter.festival-event.active {
	filter: none;
}
.upcoming .button {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #fd8300;
    color: #FFFFFF !important;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
}
@media all and (max-width:1030px) {
    #upcomingevents {
        margin-right: 0;
    }
}
@media all and (max-width:650px) {
    .controls .viewby {
        float:right;
    }
}
@media all and (max-width:500px) {
    .viewby .hover .column {
        padding-right:10px;
    }
}
/* ******************************************************************** */ 
/* Venue Summary														*/
/* ******************************************************************** */
.venuesummary {
	position:relative;
	padding-top:40px;
	padding-bottom:40px;
	background: url('../assets/img/specs-bg.png');
	text-align: center;
}
.venuesummary:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10000px;
    right: 100%;
    background: url('../assets/img/specs-bg.png');
}
.venuesummary:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -10000px;
    left: 100%;
    background: url('../assets/img/specs-bg.png');
}
.venuesummary .logo {
	text-align:center;
	padding-bottom:20px;
}
.venuesummary a {
	text-decoration:none;
	font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;
}
.venuesummary h3 {
	font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;
	font-style:normal;
	font-weight:bold;
	display:inline;
	color:#b5b5b5;
}
.venuesummary .spec {
	font-family:"Times New Roman", Times, serif;
	font-style:italic;
	font-size:14px;
	border-bottom:solid 1px #515151;
	height:34px;
	line-height:34px;
	vertical-align:middle;
	color:#fff;
}
.venuesummary .column {
	display:inline-block;
	width:290px;
	margin:20px;
    text-align: left;
}
.venuesummary .blurb {
	width:590px;
	vertical-align:top;
	font-family:"Times New Roman", Times, serif;
	font-size:14px;
	color:#fff;
	background:url('../assets/img/blurb-left.png') left no-repeat;
	padding-left:20px;
    max-width: 98%;
    margin: 1%;
    box-sizing: border-box;
}
.venuesummary .blurb p {
	padding-bottom:20px;
}
.venuesummary .column.left {
	margin-left:0;
}
.venuesummary .column.right {
	margin-right:0;
}
.venuesummary .more {
	text-align:right;
	font-size:14px;
	font-weight:bold;
	padding-top:10px;
	text-transform:uppercase;
}
.venuesummary .more.trecc a {
	color: #72b84c !important;
}
.venuesummary .more.capitol-theatre a {
	color: #e72079 !important;
}
.venuesummary .more.town-hall a {
	color: #92278f !important;
}
.venuesummary .more a img {
	vertical-align: middle;
}

/* ******************************************************************** */ 
/* eventdetail															*/
/* ******************************************************************** */
.t-newsitem .page-graphic,
.t-event .page-graphic {
    min-height: 385px;
    background-repeat: no-repeat;
    background-position: center;
}
.eventdetail {
	font-family:"Times New Roman", Times, serif;
	font-size:14px;	
	position:relative;
	margin-top:35px;
}
.eventdetail:after {
	content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.eventdetail .sidebar > *:last-child {
    border-top: none !important;
}
.t-entertainmentvenues .eventdetail .sidebar .tickets {
	color: #FD8300;
}
.t-trecc .eventdetail .sidebar .tickets {
	color: #72B84C;
}
.t-townhall .eventdetail .sidebar .tickets {
	color: #92278F;
}
.t-capitoltheatre .eventdetail .sidebar .tickets {
	color: #E72079;
}
.eventdetail h2.date {
	color:#000;
	font-size:16px;
	margin-left: 15px;
}
.eventdetail h2.date .venue {
	font-style:italic;
}
.eventdetail .sidebar {
	float:right;
	width: 300px;
	padding-left:25px;
	color:#b5b5b5;
}
.eventdetail .sidebar a.ticketlink {
	text-decoration: none;
}
.eventdetail .border {
	width: 1px;
	top:0;
	right: 315px;
	bottom:10px;
	position: absolute;
	border-right: 1px solid #d9d9d9;
}
.eventdetail .sidebar .head {
	height:40px;
	line-height:40px;
	border-top:1px solid #d9d9d9;
	border-collapse:collapse;
	font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;
	font-size:14px;
	font-weight:bold;
	/*color:#b5b5b5;*/
	text-transform:uppercase;
}
.eventdetail .sidebar .youtube-player {
	margin-bottom:20px;
	padding-top:20px;
	border-top:1px solid #d9d9d9;
}
.eventdetail .sidebar .tickets img{
	padding-top:3px;
	float:right;
}
.eventdetail .sidebar .imagerotatewrap {
	margin-bottom:20px;
	padding-top:20px;
	border-top:1px solid #d9d9d9;
	width: 300px;
}
.eventdetail .sidebar .imagerotate {
    position: relative;
    padding-top: 100%;
    overflow: hidden;
}
.eventdetail .sidebar .imagerotate > div {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transition: opacity 0.5s linear;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.eventdetail .sidebar .imagerotate div.on {
	opacity: 1;
}
.eventdetail .bodywrap {
	max-width: 660px;
	margin-bottom:30px;
	float:left;   /* TM - dynamic layout */
}
.eventdetail .bodywrap > div {
    margin-left: 15px;
    margin-right: 15px;
}
.eventdetail .bodywrap > h1 {
	
margin-left: 15px;
}
.eventdetail .sidebar .expand {
	margin-bottom:20px;
}
@media all and (max-width:1000px) {    
    .eventdetail .border {
        display:none;
    }
    .eventdetail .bodywrap,
    .eventdetail .sidebar {
        float:none;
    }
}

@media all and (max-width: 330px){
    .eventdetail .sidebar {
        width: 90%; 
    }
}
/* ******************************************************************** */ 
/* footer																*/
/* ******************************************************************** */
.pagefooter {
	font-family:"Times New Roman", Times, serif;
	font-style:italic;
	font-size:14px;
	color: #9b9b9b !important;
	margin-top: 30px;
	margin-bottom:20px;
}
.pagefooter a {
	text-decoration:none;
	color: inherit;
}
.pagefooter ul {
    padding: 0;
    display: inline-block;
}
.pagefooter ul li {
	display: inline-block;
	padding-right:10px;
}
.pagefooter table {
	width:100%;
	fd8300:solid 1px #c1c1c1;	
}

.pagefooter table .top td {
	fd8300:solid 1px #c1c1c1;
	border-top:solid 1px #c1c1c1;
}
.pagefooter table .top td.spacer {
	border:none;
	width:20px;
}

.pagefooter td {
	padding-top:10px; padding-bottom:10px;
}
.pagefooter td.bykeo,.footer td.social {
	text-align:right;
	width:220px;
}

.pagefooter td.social img {
	padding-left:20px;
}

.bykeo img {
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.bykeo a:hover img {
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}
@media all and (max-width:700px) {
    .pagefooter td {
        display:block;
    }
}
/* ******************************************************************** */ 
/* feedback																*/
/* ******************************************************************** */
.dialogfade {
	position:fixed;
	top:0;bottom:0;left:0;right:0;
	background-color:#000;
	z-index:29999;
	opacity:0.5;
}
#feedback-btn {
	cursor:pointer;
	position:fixed;
	left:0;
	top:150px;
	height:76px;
	width:35px;
	z-index:29998;
}

.t-entertainmentvenues #feedback-btn {
	background: url('../assets/img/feedback-btn-entertainmentvenues.png') center;
}
.t-trecc #feedback-btn {
	background: url('../assets/img/feedback-btn-trecc.png') center;
}
.t-townhall #feedback-btn {
	background: url('../assets/img/feedback-btn-town-hall.png') center;
}
.t-capitoltheatre #feedback-btn {
	background: url('../assets/img/feedback-btn-capitol-theatre.png') center;
}

#feedback-form {
	display:none;
	background: url('../assets/img/specs-bg.png');
	color:#fff;
	border-radius:10px;
	position:absolute;
	top:200px;
	left:50%;
	width:400px;
	margin-top:20px;
	margin-left:-200px;
	padding:20px;
	z-index:30000;
	font-family:"Times New Roman", Times, serif;
	font-weight:normal;
	font-style:italic;
	line-height:30px;
}
#feedback-form input, #feedback-form textarea{
	border:none;
	width:250px;
	height:30px;
	margin-top:10px;
	padding-left:5px;
}
#feedback-form textarea {
	height:200px;
}
#feedback-form input[type='submit'] {
	width:auto;
	border:none;
	font-size: 22px;	
	text-transform: uppercase;
	font-family: "Century Gothic", "Tw Cen MT", Futura, "URW Gothic L", Arial, sans-serif;
	color: white;
	background-color:transparent;
	font-weight:bold;
	font-style:italic;
	cursor:pointer;
}
#feedback-form label.error {
	display:block;
	color:#000;
}
/* ******************************************************************** */ 
/* cssda award															*/
/* ******************************************************************** */
#cssda {
	display:none;
}
@media screen and (min-width:1100px) {
	#cssda {
		display:block;
		position:absolute;
		right:0;
		top:160px;
	}
}
/* ******************************************************************** */
#cssreel {
	display:none;
}
@media screen and (max-width: 1225px) {
	.bodywrap,
	.sidebar {
		float: none !important;
	}
	.eventdetail .border {
		display: none;
	}
}
@media screen and (min-width:1100px) {
	#cssreel {
		display:block;
		position:absolute;
		right:0;
		top:0px;
	}
}
@media screen and (max-width: 500px) {
	.upcoming {
		padding: 25px 0 50px;
	}
	.topbanner {
		padding-top: 30px;
	}
}
@media screen and (max-width: 450px) {
	.eventdetail .sidebar {
		width: 100%;
		padding-right: 25px;
		box-sizing: border-box;
	}
}


/* ******************************************************************** */
/* Seat advisor frame   												*/
/* ******************************************************************** */
#sabo {
    height:90vh;
}